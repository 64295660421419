module.exports = {
  // App Info
  appstore_link         : "https://apps.apple.com/us/app/quiet-time-bible/id1459156109?ls=1",                                  // Enter App Store URL.
  playstore_link        : null,                                  // Enter Google Play Store URL.
  google_analytics_ID   : "UA-62020128-2",                                        // Enter Google Analytics ID or ""
  presskit_download_link: null,                                  // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://quiettimebible.co.uk",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Quiet Time",
  app_price             : "Free",
  app_description       : "Modern, focused, beautifully designed bible app. Built for iPhone & iPad, exclusivly on iOS",
  app_keywords          : ["bible", "quiet time", "christian", "iOS", "app", "app store"],

  // Personal Info
  your_name              : "Matthew Wilson",
  your_link              : "https://twitter.com/matt_codes",
  your_city              : "Belfast, Northern Ireland",
  email_address          : "matthew@quiettimebible.co.uk",
  linkedin_username      : null,
  facebook_username      : null,
  instagram_username     : null,
  twitter_username       : "QuietTimeBible",
  github_username        : "matthewwilson",
  youtube_username       : null,

  // Features List
  features               : [
    {
      title: "Native",
      description:
        "Designed from the ground up to make the most of the latest iOS features",
      fontawesome_icon_name: "mobile",
    },
    {
      title: "Bookmarks",
      description:
        "Bookmark your favourite verses. And sync between devices using iCloud",
      fontawesome_icon_name: "bookmark",
    },
    {
      title: "Sharing",
      description:
        "Easily share verses with a few taps",
      fontawesome_icon_name: "share",
    },
    {
      title: "Read Mode",
      description:
        "Distraction free read mode which hides verse numbers and line breaks",
      fontawesome_icon_name: "book",
    },
    {
      title: "Study Mode",
      description:
        "Study God's word verse by verse",
      fontawesome_icon_name: "align-left",
    },
    {
      title: "Dark Mode",
      description:
        "Beautiful dark mode looks great on the latest iOS devices",
      fontawesome_icon_name: "adjust",
    },
  ],
  header_background             : "rgba(0, 0, 0, 0.1)",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  device_color                  : "black",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "ffffff",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#1d63ea",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#000000",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
